import format from "date-fns/format";
import React, { createElement, MouseEventHandler, ReactNode, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import decodeLocationSlug from "../../../utils/decode-location-slug";
import useClx from "../../hooks/use-clx";
import DiscountPercentage from "../LoyaltyPointShortTicker";
import clxs from "./property-item.module.css";
import CHEVRON_RIGHT_PRIMARY1 from "../../assets/icons/black-right-arrow-icon.svg";
import Infinity_Pool_Icon from "../../assets/icons/infinitypool-logo.svg";
import CHEVRON_RIGHT_PRIMARY2 from "../../assets/lohono-black/icons/chevron-right-primary.svg";
import LOHONO_BLACK_LOGO from "../../assets/lohono-black/logo/lohono-black.svg";
import RatingCounts from "../PropertyDetail/RatingCounts";
import useIsMobile from "../../hooks/use-is-mobile";
import Carousel from "../Carousel";
import Item from "../Carousel/Item";
import ScrollCrumb from "../Home/PropertyCarousel/ScrollCrumb";
import VillaTag from "./VillaTag";
import NetworkImg from "../NetworkImg";

interface PropertyItemProps {
  property: PropertyItem;
  className?: string;
  routable?: boolean;
  subscript?: string;
  children?: ReactNode;
  onClick?: MouseEventHandler;
}

function PropertyItem(props: PropertyItemProps) {
  const [search] = useSearchParams(),
    carouselRef = useRef<HTMLDivElement | null>(null),
    {
      property,
      routable = true,
      className,
      //subscript = "/night",
      children,
      onClick,
    } = props,
    {
      location_slug,
      property_slug,
      name,
      address,
      accommodation,
      pool_count,
      bath_count,
      currency_symbol,
      rate,
      discounted_rate,
      sold_out,
      upcoming,
      status,
      brand,
      bedroom_count,
      configs,
      next_availability,
      images,
      builder,
      discount_method,
      discount_type,
      discount_value,
      //loyalty_point_discount_percentage,
      average_ratings,
      ratings_count,
      tagsName,
      tagsIcon,
      //custom_discount_percentage,
      collections,
      bundeled_offer,
      total_rate,
      coupon_code,
    } = property,
    guest_count = useMemo(() => {
      const guest = accommodation.split(" ");
      const count = parseInt(guest[guest.length - 1], 10);
      return count;
    }, [accommodation]),
    { isMobile } = useIsMobile(),
    carouselId = useMemo(
      () => `${CAROUSEL_ID}-${property_slug}`,
      [property_slug],
    ),
    specs = useMemo(
      () => {
        const specs: string[] = [
          `${accommodation} ${guest_count > 1 ? "Guests" : "Guset"}`,
        ];

        if (configs?.length) {
          const partial = configs?.map(each => each.bedrooms).join(", ");

          specs.push(`${partial} Bedrooms`);
        }

        if (bath_count) {
          specs.push(`${bath_count} ${bath_count > 1 ? "Baths" : "Bath"}`);
        }

        if (pool_count) {
          specs.push(`${pool_count} ${pool_count > 1 ? "Pools" : "Pool"}`);
        }

        return specs;
      },
      [accommodation, bath_count, pool_count, configs],
    ),
    strikePrice = useMemo(
      () => {
        if (!discounted_rate) {
          return "";
        }
        if (discounted_rate == rate) {
          return "";
        }
        return `${currency_symbol} ${rate.toLocaleString("en-IN")}`;
      },
      [discounted_rate, rate, currency_symbol],
    ),
    finalPrice = useMemo(
      () => {
        const finalPrice = discounted_rate || rate;
        if (discounted_rate || rate) {
          return `${currency_symbol} ${(finalPrice).toLocaleString("en-IN")}`;
        }
        return null;
      },
      [discounted_rate, rate, currency_symbol],
    ),
    totalPrice = useMemo(
      () => {
        const totalPrice = total_rate;
        if (total_rate) {
          return `${currency_symbol}${(totalPrice).toLocaleString("en-IN")}`;
        }
        return null;
      },
      [total_rate, currency_symbol],
    ),
    { location, destination } = useMemo(
      () => decodeLocationSlug(location_slug),
      [location_slug],
    ),
    isUnavailable = useMemo(
      () => status === "unavailable" || upcoming,
      [status, upcoming],
    ),
    isBlack = useMemo(
      () => brand === "lohono_black",
      [brand],
    ),
    ccx = useClx(
      clxs.container,
      isBlack ? clxs.black : null,
      className,
    ),
    rootElement = useMemo(
      () => {
        if (!routable) {
          return "div";
        }
        return "a";
      },
      [routable],
    ),
    nextAvailableDateNode = useMemo(
      () => {
        if (!sold_out) {
          return null;
        }

        const { checkin_date, checkout_date } = next_availability;

        if (!checkin_date || !checkout_date) {
          return null;
        }

        const checkin = format(new Date(checkin_date), "d LLL"),
          checkout = format(new Date(checkout_date), "d LLL");

        return (
          <div className={clxs.datesContainer}>
            {isMobile ? "- Next Available Dates" : "Next Available Dates"}
            <div className={clxs.dates}>
              {isMobile ? ` : ${checkin} - ${checkout}` : `${checkin} - ${checkout}`}
            </div>
          </div>
        );

      },
      [
        next_availability,
        sold_out,
        clxs.datesContainer,
        clxs.dates,
      ],
    ),
    tickerNode = useMemo(
      () => {
        if (upcoming) {
          return (
            <div className={clxs.upcoming}>
              Coming soon
            </div>
          );
        }

        if (sold_out) {
          return (
            <div className={clxs.soldOut}>
              Sold out
            </div>
          );
        }

        if (isUnavailable) {
          return (
            <div className={clxs.unavailable}>
              Unavailable
            </div>
          );
        }

        return null;
      },
      [upcoming, sold_out, isUnavailable],
    ),
    builderBadge = useMemo(
      () => {
        if (isBlack) {
          return (
            <img
              src={LOHONO_BLACK_LOGO}
              alt="lohono-black"
              className={clxs.badge}
            />
          );
        }
        return null;
      },
      [builder, isBlack],
    ),
    renderSoldOut = useMemo(() => {
      //If soldout or upcomming is true and nextAvailableDate is not present then
      const condition1 = (upcoming || sold_out) && !nextAvailableDateNode;

      //If sold_out ,upcoming ,isUnavailable is true
      const condition2 = sold_out || upcoming || isUnavailable;

      if (condition1) {
        return <div className={ratings_count ? clxs.soloSoldOut : `${clxs.soloSoldOut} ${clxs.leftContainer}`}>{tickerNode}</div>;
      } else if (condition2) {
        return (
          <div className={ratings_count && average_ratings ? clxs.soldOutContainer : `${clxs.soldOutContainer} ${clxs.leftContainer}`}>
            {tickerNode}
            {nextAvailableDateNode}
          </div>
        );
      } else {
        return null;
      }
    }, [sold_out, upcoming, nextAvailableDateNode, isUnavailable]),
    cProps = useMemo(
      () => {
        const baseProps = {
          className: ccx,
          onClick: onClick,
        };

        if (!routable) {
          return baseProps;
        }

        const updatedSearchParams = new URLSearchParams(search);

        if (!updatedSearchParams.has("adult_count")) {
          updatedSearchParams.set("adult_count", "2");
        }

        if (
          sold_out
          && next_availability.checkin_date
          && next_availability.checkin_date
        ) {
          updatedSearchParams.set(
            "checkin_date",
            new Date(next_availability.checkin_date).toISOString(),
          );

          updatedSearchParams.set(
            "checkout_date",
            new Date(next_availability.checkout_date).toISOString(),
          );
        }

        if (coupon_code) {
          updatedSearchParams.set("coupon_code", coupon_code);
        }

        const safeSearch = updatedSearchParams.toString(),
          path = `/villas/${destination}/${location}/${property_slug}`,
          url = safeSearch ? `${path}?${safeSearch}` : path;

        return {
          ...baseProps,
          href: url,
          title: `View details of ${name}`,
        };
      },
      [
        routable,
        name,
        location,
        destination,
        property_slug,
        next_availability,
        sold_out,
        ccx,
        onClick,
        search,
      ],
    ),
    preventRedirect = (e: React.MouseEvent) => {
      e.preventDefault();
    };

  return createElement(
    rootElement,
    cProps,
    <>
      <div
        className={clxs.thumbnailContainer}
        onClick={preventRedirect}
      >
        { images?.length ?
          <Carousel
            className={clxs.thumbnail}
            id={carouselId}
            threshold={0.98}
            forwardRef={carouselRef}
          >
          {images?.map((each, key) => {
            const { url, alt } = each;
            return (
              <Item 
                className={clxs.item} 
                key={key}
              >
                <NetworkImg
                  src={url}
                  alt={alt}
                  className={clxs.imgSelect}
                  {...(key !== 0 && { loading: "lazy" })}
                />  
              </Item>
            );
          })}
        </Carousel>
        : []
      }
        <ScrollCrumb
          carouselId={carouselId}
          count={images?.length}
          className={clxs.scrollCrumb}
          isShowArrows={true}
          isFilledArrows={true}
        />
      </div>
      <h3
        className={clxs.title}
        style={{ gridColumn: (((ratings_count && average_ratings) || sold_out) ? "span 2" : "span 3") }}
        title={name}
      >
        {name}
      </h3>
      {/* Check if both ratings_count and average_ratings */}
      {(ratings_count && average_ratings) ?
        <RatingCounts
          className={clxs.ratingCounts}
          average_ratings={average_ratings}
          ratings_count={ratings_count}
          theme="propertyItemRating"
        /> : null}
      <div className={clxs.subtitle}>
        {address}
      </div>
      <div className={clxs.specContainer}>
        {specs?.map(
          (each, i) => (
            <div
              key={i}
              className={clxs.spec}
            >
              {each} <span className={clxs.dot}>&#x2022;</span>
            </div>
          ),
        )}
      </div>

      {collections?.length ?
        <div className={clxs.collectionContainer}>
          {collections?.map(
            (each, i) => (
              <div
                key={i}
                className={clxs.collectionContent}
              >
                <img
                  src={each.icon ? each.icon : Infinity_Pool_Icon}
                  alt={each.slug}
                  className={clxs.collectionIcon}
                />
                <div className={clxs.collection}>
                  {each.name}
                </div>
              </div>
            ),
          )}
        </div>
        :
        <div className={clxs.hideCollection}></div>
      }

      <div className={clxs.horizontalDivider}></div>

      {isUnavailable ? (<div>&nbsp;</div>) : (
        <div className={ collections?.length ? clxs.pricingContainer : `${clxs.pricingContainer} ${clxs.priceHeight}`}>
          {configs.length > 1 ? <div className={clxs.bedroomCount}>For {bedroom_count} rooms</div> : null}

          <div className={clxs.priceContainer}>
            {strikePrice ?
              <div className={clxs.startFrom}>
                {discount_value  ?
                <DiscountPercentage
                  discountType={discount_type}
                  discountPercentage={discount_value}
                  method={discount_method}
                  currencySymbol={currency_symbol}
                  className={clxs.llpTicker}
                /> : null }
                {strikePrice ? (
                  <span className={clxs.strike}>
                    {strikePrice}
                  </span>
                ) : null}
              </div>
              : null
            }
            <div
              className={clxs.price}
              data-sold-out={sold_out}
            >

              <span className={clxs.amount}>
                {finalPrice ? finalPrice : "-"}
              </span>
              <span className={clxs.excTax}>{isMobile ? "/ night excluding taxes*" : "Per night excluding taxes*"}</span>
            </div>

            <div
              className={clxs.totalAmount}
              data-sold-out={sold_out}
            >
              Total: {totalPrice ? totalPrice : "-"}
            </div>
          </div>

          <div
            className={clxs.view}
            data-sold-out={sold_out}
          >
            <span>
              View details
            </span>
            <img
              src={isBlack ? CHEVRON_RIGHT_PRIMARY2 : CHEVRON_RIGHT_PRIMARY1}
              alt="jump"
            />
          </div>
        </div>
      )}
      {builderBadge}
      {renderSoldOut}
      {bundeled_offer ?
        <div className={clxs.additionalBenefitsContainer}>
          <div className={clxs.additionalBenefits}>Additional benefits on</div>
          <div className={clxs.benefitsContainer}>
            <img
              // src={isMobile ? fork_spoon_color_icon : fork_spoon_icon}
              src={bundeled_offer.icon}
              alt="Lohono"
              className={clxs.benefitsIcon}
            />
            <span className={clxs.benefitsContent}>{bundeled_offer.name}</span>
          </div>
        </div>
        : null
      }
      {tagsName &&
        <div className={clxs.VillaTagContainer}>
          <VillaTag
            tag={tagsName}
            icon={tagsIcon}
            className=""
          />
        </div>
      }
      {children}
    </>,
  );
}

export default PropertyItem;

const CAROUSEL_ID = "property-details-image";